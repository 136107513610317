import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Curso from "../components/curso";
import Tree from "../images/cursos/Tree.webp";
import Ola from "../images/cursos/Ola.webp";

const Budas = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted ? (
    <Layout>
      <Curso
        title="Enojo y miedo"
        phrase="Aceptarlos, comprenderlos y transformarlos para crear relaciones saludables."
        time="4 Horas"
        class="4 Sesiones"
        students="3 - 6 Estudiantes"
        firstImg={Tree}
        secondImg={Ola}
        description="A través de la comprensión de las 6 perfecciones budistas, comprenderemos el objetivo final del budismo, que es convertirnos en seres despiertos, libres y altruistas."
        list={[
          "Comprendiendo el enojo",
          "Relación entre miedo y enojo",
          "Diferencia entre miedo y ansiedad",
          "Estrategias para trabajar en enojo y miedo",
        ]}
        duracion="4 sesiones de 60 min."
        cupo="mínimo 3 personas, máximo 6 personas."
        precio=" $2,000 MXN / $120 USD aprox."
        top="20%"
        imgWidth="20%"
        right="5%"
        topMob="20%"
        rightMob="5%"
        imgWidthMob="35%"
        instagram="gabiunikel"
      />
    </Layout>
  ) : null;
};

export default Budas;
